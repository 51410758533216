import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin:0;
    padding: 0;  
  }
  body {
    font-family: 'Poppins', sans-serif !important;
    font-size: 16px;
    color: #212529 !important;
    background-color: #F0F3F8 !important;
  }
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
  }
  img {
    max-width: 100%;
  }
  a:hover {
    text-decoration: none;
    color: #212529;
    outline: none;
  }
  .Toastify__toast {
    width: 370px;
    max-width: 100%;
    overflow: inherit;
    margin: 0;
    padding: 0;
  }
  .Toastify__toast-container {
    width: 370px;
    max-width: calc(100% - 30px);
    .Toastify__close-button {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 26px;
      height: 26px;
      line-height: 0px;
      background: none;
      color: #9d9d9e;
      text-align: center;
      opacity: 1;
      margin: 0;
      padding: 0;
      border-radius: 100px;
      &:focus {
        border: none;
        outline: none;
      }
    }
  }
  .font-mono {
    font-family: 'monospace';
  }
  .form-group {
    margin-bottom: 0px;
  }
  .form-control:focus {
    box-shadow: none !important;
  }
  .btn {
    padding: 8px 24px;
    border-radius: 6px;
  }

  .stord-card-modal {
    max-width: 560px;
  }

  .save_payment_method {
    .method_list {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        padding: 10px;
        border: 1px solid #ededed;
        margin-bottom: -1px;
        cursor: pointer;

        &.active {
          border-color: #136acd60;
          background-color: #136acd10;
          position: relative;
          z-index: 1;
        }

        &:first-child {
          border-radius: 10px 10px 0 0;
        }

        &:last-child {
          border-radius:  0 0 10px 10px;
        }     

        .card_icon {
          line-height: 0;
          border-radius: 5px;
          overflow: hidden;
          margin-right: 15px;
          width: 60px;
        }

        .card_number {
          font-weight: 500;

          &:before {
            content: "•••• •••• •••• ";
          }
        }   

        .card_cvv {
          margin-left: auto;
          
          input {
            border: 1px solid #ededed;
            border-radius: 5px;
            width: 70px;
            font-weight: 500;
            padding: 5px 10px;
            text-align: center;
            outline: none;
            background: #ffffff;
          }
        }
        
                
        &.active {
          .card_cvv input {
            border-color: #136acd60;
          }
        }
      }
    }
  }
  
  .modal-content {
    border-radius: 12px;
  }

  .otp-column-border {
    border-left: 1px solid #acacac;
    @media (max-width: 992px) {
      border-left: none;
      padding-top: 30px;
      margin-top: 30px;
      border-top: 1px solid #acacac;
    }
  }

  .mxw-100 {
    max-width: 100px !important;
  }

  .mxw-150 {
    max-width: 150px !important;
  }

`
export default GlobalStyle
