import React from 'react'
import styled from 'styled-components'
import { Spinner } from 'reactstrap'

const getRingColor = (success, danger, info, warning) => {
  if (success) {
    return '#00cc96'
  }
  if (danger) {
    return `#e9615b`
  }
  if (info) {
    return '#1c61b2'
  }
  if (warning) {
    return '#ffc107'
  }
  return '#1c61b2'
}

export const LOAD = styled.div`
  background-color: #f0f3f8;
  position: ${({ isPopup }) => (isPopup ? 'relative' : 'fixed')};
  left: 0px;
  top: 0px;
  width: 100%;
  height: ${({ isPopup }) => (isPopup ? '185px' : '100%')};
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner-box {
    position: relative;
    display: inline-block;
    z-index: 999999999999;

    .spinner-border {
      width: 150px;
      height: 150px;
      color: ${({ success, danger, info, warning }) =>
        getRingColor(success, danger, info, warning)} !important;
      border-right-color: ${({ success, danger, info, warning }) =>
        info || (!info && !danger && !success && !warning)
          ? 'transparent'
          : getRingColor(success, danger, info, warning)};
    }

    &:before {
      content: '';
      position: absolute;
      left: 53%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: 50%;
      display: block;
      z-index: 1;
      background: url('${process.env.REACT_APP_CDN_URL}/static/payyit/icon-logo.png')
        no-repeat scroll center center/contain;
    }
  }
`

export default function Loader({ isPopup, success, danger, info, warning }) {
  return (
    <LOAD
      isPopup={isPopup}
      success={success}
      danger={danger}
      info={info}
      warning={warning}
    >
      <div className="spinner-box">
        <Spinner className="spiner" color="primary" />
      </div>
    </LOAD>
  )
}
