import { lazy } from 'react'

const InvoiceById = lazy(() => import('../pages/Invoice'))
const EstimateById = lazy(() => import('../pages/Estimate'))
const StatementById = lazy(() => import('../pages/Statement'))
const PeymeById = lazy(() => import('../pages/Peyme'))
const CheckoutById = lazy(() => import('../pages/Checkout'))
const CrowdFundingById = lazy(() => import('../pages/CrowdFunding'))
const EndCrowdFundingById = lazy(() => import('../pages/EndCrowdFunding'))
const NotFound = lazy(() => import('../pages/NotFound'))
const Home = lazy(() => import('../pages/Home'))

const MobileInvoiceById = lazy(() => import('../mobile/invoice'))
const MobileCheckoutById = lazy(() => import('../mobile/checkout'))

const publicRoutes = [
  { path: '/invoice/:id', exact: false, component: InvoiceById },
  { path: '/checkout/:id', exact: false, component: CheckoutById },
  { path: '/estimate/:id', exact: false, component: EstimateById },
  { path: '/statement/:id', exact: false, component: StatementById },
  { path: '/for/:id', exact: false, component: PeymeById },
  { path: '/give/:id', exact: true, component: CrowdFundingById },
  { path: '/give/:fundingId/:orderId', exact: true, component: EndCrowdFundingById },
  { path: '/mobile/invoice/:id', exact: false, component: MobileInvoiceById },
  { path: '/mobile/checkout/:id', exact: false, component: MobileCheckoutById },
  { path: '/404', exact: false, component: NotFound },
  { path: '/', exact: false, component: Home },
  { path: '*', exact: false, component: NotFound }
]

export default publicRoutes
